














































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

@Component
export default class ServiceMessageShow extends Vue {
  message = []
  created () {
    axios.get(`/services/message/${this.$route.params.id}`)
      .then(response => {
        this.message = camelCaseKeys(response.data.data, { deep: true })
      })
  }
}
